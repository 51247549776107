const jump = {
  data() {
    return {
      si: null,
    }
  },
  mounted() {
    if (this.si) {
      clearInterval(this.si)
    }

    if (this.$route.hash) {
      let count = 0
      this.si = setInterval(() => {
        console.info(this)
        if (document.readyState == 'complete') {
          const el = document.querySelector(this.$route.hash)
          console.info(el)
          if (el || count > 20) {
            el?.scrollIntoView(true)
            clearInterval(this.si)
            this.si = null
          }
          count++
        }
      }, 100)
    }
  },
  methods: {
    scrollToHash() {},
  },
}

export default jump
