<template>
  <div>
    <!-- <img class="demo" src="/img/demo/join.jpg" /> -->
    <img class="block" src="/img/join/1.jpg" />
    <div id="jp1" class="wrap">
      <div class="content">
        <h1>岗位招聘</h1>
        <div class="">
          <img src="/img/join/5.jpg" />
        </div>
        <div class="p2">
          <img src="/img/join/7.jpg" />
        </div>
      </div>
    </div>
    <img id="jp2" class="block b2" src="/img/join/2.jpg" />
    <img id="jp3" class="block b3" src="/img/join/3.jpg" />
    <img class="block b4" src="/img/join/4.jpg" />
  </div>
</template>
<script>
  import jump from '@/mixins/jump'
  export default {
    mixins: [jump],
  }
</script>
<style lang="scss" scoped>
  .wrap {
    margin-top: 40px;
    background: #f4f4f4;
  }
  .content {
    width: 1440px;
    padding: 100px 0 100px 61px;
    margin: 0 auto;

    h1 {
      margin-bottom: 135px;
      font-size: 40px;
      text-align: center;
    }
    .p2 {
      margin-top: 100px;
    }
  }
  .b2 {
    margin-top: 210px;
  }
  .b3 {
    margin-top: 210px;
  }
  .b4 {
    margin-top: 130px;
  }
</style>
